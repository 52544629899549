<mat-toolbar  class="mat-elevation-z4">
  <div class="header-logo-container" style="--fill:#e65100;">
    
    <img class="header-logo" src="assets/truelogoF.png" routerLink="/" />
    <!-- <div style="height: 60px; width: 100px">
      <svg id="emgi5vuYIQP1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 600 320" shape-rendering="geometricPrecision" text-rendering="geometricPrecision">
        <g transform="matrix(.461097 0 0 0.461097-197.287064-492.453409)">
          <path  class="logo" d="M510.721143,1110.388857h197.654461q-5.235879,95.554806,0,95.554806c5.235879,0-36.651159-3.926909-36.651159,0q0,3.926909,0,166.239182l47.122918,27.488369-45.813948,26.179398v157.076392h62.830557v-48.431888h125.661114v167.548153h-350.803944v-119.116265q56.285707,
          0,54.976737,0t0-157.076392l-54.976737-26.179398l43.196007-27.488369v-159.694335h-43.196007l.000001-102.099653" transform="translate(.000002-1.308968)" fill="#f00" stroke="#3f5787" stroke-width="15"/>
          <path class="logo" d="M1112.847317,1109.079889q124.352144,0,125.661114,0t0,175.40197q-130.386129,21.294366-126.970085,0c3.416044-21.294366-2.475826-65.570645-26.179399-73.302317-17.78287.557688-36.734753,15.639024-34.033218,34.033219q-6.54485,
          54.976738-6.54485,53.667768t-1.308971,58.903647l-1.30897,60.212617q1.308971,61.521587,2.617941,61.521587t2.617938,66.757468c.860676,15.311019,7.697309,35.21101,17.016609,35.342187c16.806796,11.775166,30.727154,13.839334,35.342189,
          10.47176s18.325579-34.033219,17.016609-35.342189q-1.30897-1.30897-2.61794-43.196008-3.926908-32.724248-2.617938-34.033218c1.30897-1.30897-24.870431,5.23588-24.870431,0s0-98.172746,0-99.481716s143.986694,1.30897,145.295664,0q1.30897-1.30897,
          0,218.597981c-9.075052,53.235875-57.715984,123.402133-134.823904,117.807294-87.378775.248516-141.955397-41.954633-166.239183-119.116264-.7859-2.497193-2.61794-146.604634-2.61794-147.913604q0-1.30897-52.358798-24.870428q51.049828-35.342191,
          51.049828-36.651161c0-1.30897-.028361-96.86408,0-98.172744.872647-40.266384,6.54485-68.080423,17.016609-104.717596l34.033219-51.049827c21.584524-14.984135,45.62482-23.276934,71.993347-24.870427q26.368527-1.593493,35.342188-.000001l27.488369,
          26.179396q-5.235874-26.179391.000003-26.179394Z" transform="translate(.000003-1.308967)" fill="#f00" stroke="#3f5787" stroke-width="15"/>
          <path class="logo" d="M1324.900448,1391.817395l1.30897-153.149484c-2.10941-49.747467,44.96236-113.619075,91.627896-129.588022l54.976737-1.308969l37.960128,26.179395l2.617939-24.870427h112.571415q2.617941,179.328882,0,
          179.328882t-123.043176,0c5.36803-26.713032.122206-67.77948-30.106307-77.229229-33.035728,15.147426-42.616524,50.349302-36.65116,71.99335q.3478,1.261917,0,261.793989c1.217633,34.556973,17.660987,68.086951,36.65116,
          58.903644c31.695195,3.762213,35.696765-39.820345,32.724247-70.684377h138.750814q3.92691,176.710943,0,176.710943c-3.92691,0-124.352145-5.235881-124.352145,0s-9.206138-27.487652-9.162788-26.179399c.756343,22.825736-63.091592,
          38.418956-74.611288,39.269095-22.301331,1.64581-112.936761-42.900484-111.262443-130.896989q1.674318-87.996505,0-143.986696l-49.900451-24.870429l49.900452-31.415277Z" transform="translate(.000004 0.000004)" fill="#f00" stroke="#3f5787" stroke-width="15"/>
        </g>
      </svg>

    </div> -->
  </div>
  <span class="fill-remaining-space"></span>
  <ng-container *ngIf="authService.isSignedIn()">
    
    <ng-container *ngIf="showLogin()">
      
      <!-- <button mat-flat-button color="login" class="name"
        [matMenuTriggerFor]="accountActionsMenu">{{authService.getName()}}</button>

      <mat-menu #accountActionsMenu="matMenu" xPosition="before">
        
        <div class="mat-hint mat-menu-label">{{authService.getEmail()}}</div>
        <mat-divider></mat-divider>

        <button mat-menu-item  (click)="authService.signout()" >Sign Out</button>

       


      </mat-menu> -->
      <div class="name">
        Hello, {{authService.getName()}}
      </div>


      <div (click)="authService.signout()" class="logout">
        Sign Out
      </div>

    </ng-container>

    <ng-container *ngIf="!showLogin()">
      <!-- <button mat-flat-button color="login" (click)="authService.signout()">Sign Out</button> -->

      <div (click)="authService.signout()" class="logout">
        Sign Out
      </div>

    </ng-container>
  </ng-container>
</mat-toolbar>


<router-outlet></router-outlet>